import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import './Slider.css';
import { Carousel } from 'react-responsive-carousel';

function Slider() {


    return (
        <div className='slider'>
            <Carousel
                autoPlay={true}
                infiniteLoop={true}
                interval={4500}
                showArrows={false}
                showStatus={false}
                showIndicators={false}
                showThumbs={false}
                stopOnHover={true}
                swipeable={false}
                transitionTime={1500}
                useKeyboardArrows={false}
            >
                <img src={process.env.PUBLIC_URL + '/1.jpg'} alt="" />
                <img src={process.env.PUBLIC_URL + '/2.jpg'} alt="" />
                <img src={process.env.PUBLIC_URL + '/3.jpg'} alt="" />
            </Carousel>
            <div className='settemotivi-logo'>
                <img src={process.env.PUBLIC_URL + '/settemotivi-logo.png'} alt="" />
            </div>
        </div>
    )
}

export default Slider;