import { Link } from "react-router-dom";
import './Wip.css'

function Wip() {


    return (
        <div className="center">
            <div className="text space">
                Settemotivi
            </div>
            <div>
                <img src={process.env.PUBLIC_URL + '/abstract.svg'} style={{ 'width': '100%' }} alt=""/>
            </div>
            <div className="text space">
                Lavori in corso!
            </div>
            <div style={{ 'fontSize': 'xx-small', 'position': 'fixed', 'bottom': '0' }}>
                Image by <a
                    href="https://pixabay.com/users/gdj-1086657/?utm_source=link-attribution&amp;utm_medium=referral&amp;utm_campaign=image&amp;utm_content=2027962">Gordon
                    Johnson</a> from <a
                        href="https://pixabay.com/?utm_source=link-attribution&amp;utm_medium=referral&amp;utm_campaign=image&amp;utm_content=2027962">Pixabay</a>
            </div>
            <Link to={"trasparenza"}>Settemotivi - Legge Trasparenza Anno 2023 </Link>
            <Link to={"supersecret"} style={{'color': 'lightgray'}}>error</Link>
        </div>

    )
}

export default Wip;
