import { Outlet } from 'react-router-dom';
import './MainContent.css'

function MainContent() {


    return(
        <div className="main-content-container">
            <Outlet />
        </div>
    )
}

export default MainContent;