import { Outlet } from "react-router-dom";

function EmptyPage() {


    return(
        <>
            <Outlet />
        </>
    )
}

export default EmptyPage;