import './Footer.css';

function Footer() {


    return (
        <div className="footer-container">
            <div>Ass. Settemotivi - ODV - C.F. 911 000 00 933</div>
        </div>
    )
}

export default Footer;