import './App.css';
import Slider from './Slider';
import Nav from './Nav';
import PageContentContainer from './PageContentContainer';




function App() {


  console.log(`APP: ${process.env.PUBLIC_URL}`)


  return (
    <div id='app'>
      <header>
        <Slider />
        <Nav />
      </header>
      <PageContentContainer />
    </div>
  );
}

export default App;
