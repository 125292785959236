import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import ErrorPage from './ErrorPage';
import Focus from './Focus';
import Home from './Home';
import Wip from './Wip';
import Trasparenza from './Trasparenza';
import EmptyPage from './EmptyPage';


const router = createBrowserRouter([
  {
    path: "/",
    element: <EmptyPage />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: "/",
        element: <Wip />
      },
      {
        path: "trasparenza",
        element: <Trasparenza />
      },
      {
        path: "supersecret",
        element: <App />,
        children: [
          {
            path: "supersecret",
            element: <Home />,
          },
          {
            path: "focus",
            element: <Focus />,
          },
        ]
      },
    ],
  },],
);

console.log('react working');

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);