import { Link } from 'react-router-dom';
import './Nav.css'

function Nav() {



    return (
        <nav>
            <ul>
                <li className='first-level-menu'>
                    <Link to={`/supersecret`}>HOME</Link>
                </li>
                <div className='menu-divider'></div>
                <li className='first-level-menu subnav'>
                    <div className='link-container'>
                        <a href="" onClick={(e) => e.preventDefault()}>CHI SIAMO</a>
                        <img src={process.env.PUBLIC_URL + '/arrow-down.png'} alt="" />
                    </div>
                    <ul className='submenu'>
                        <li className='second-level-menu'>
                            <Link to={`focus`}>Focus</Link>
                        </li>
                        <li className='second-level-menu'>
                            <a href="">Trasparenza</a>
                        </li>
                    </ul>
                </li>
                <div className='menu-divider'></div>
                <li className='first-level-menu subnav'>
                    <div className='link-container'>
                        <a href="" onClick={(e) => e.preventDefault()}>PROGETTI</a>
                        <img src={process.env.PUBLIC_URL + '/arrow-down.png'} alt="" />
                    </div>
                    <ul className='submenu'>
                        <li className='second-level-menu'><a href="">Progetto 1</a></li>
                        <li className='second-level-menu'><a href="">Progetto 2</a></li>
                    </ul>
                </li>
                <div className='menu-divider'></div>
                <li className='first-level-menu'><a href="">FOTO</a></li>
                <div className='menu-divider'></div>
                <li className='first-level-menu logo-menu'>
                    <a href="https://www.youtube.com/@settemotivi-odv349" target='_blank' rel="noreferrer">
                        <div className='social-link'>
                            <img className='social-logo' src={process.env.PUBLIC_URL + '/youtube-logo.png'} alt="" />
                        </div>
                    </a>
                </li>
                <div className='menu-divider'></div>
                <li className='first-level-menu'><a href="">CONTATTI</a></li>
                <div className='menu-divider'></div>
                <li className='first-level-menu'><a href="">DONAZIONI</a></li>
                <div className='menu-divider'></div>
                <li className='first-level-menu logo-menu'>
                    <a href="https://www.facebook.com/settemotiviodv" target='_blank' rel="noreferrer">
                        <div className='social-link'>
                            <img className='social-logo' src={process.env.PUBLIC_URL + '/facebook-logo.png'} alt="" />
                        </div>
                    </a>
                </li>
            </ul>
        </nav>
    )
}

export default Nav;