import './Aside.css'


function Aside() {
    var height = 700;
    var width = 1000;
    var top = (window.outerHeight - height) / 2;
    var left = window.screen.availWidth + (window.screen.availWidth - width) / 2;
    const linkRadioCosmoSite = 'https://www.radiocosmo.it/';
    const linkRadioCosmoLive = 'https://www.inmystream.app/player2/radiocosmo.html';
    const newPage = '';
    const newPageResized = 'location=yes,height=' + height + ',width=' + width + ',top=' + top + ',left=' + left + ',scrollbars=yes,status=yes';


    function openWindow(url: string, newPage: string) {
        window.open(
            url,
            "_blank",
            newPage
        );
    };

    return (
        <div className="aside-container">
            <div className='five-x-container'>
                <img className='logo-fit' src={process.env.PUBLIC_URL + '/5x1000.png'} alt="" />
            </div>
            <div className='radio-cosmo-container'>
                <div className='radio-cosmo-site'>
                    <img
                        className='logo-fit'
                        src={process.env.PUBLIC_URL + '/radio-cosmo-logo.png'}
                        alt=""
                        onClick={() => openWindow(linkRadioCosmoSite, newPage)}
                    />
                </div>
                <div id='radio-cosmo-live' onClick={() => openWindow(linkRadioCosmoLive, newPageResized)} >
                    <p>ASCOLTA RADIOCOSMO</p>
                </div>
            </div>
        </div>
    )
}

export default Aside;