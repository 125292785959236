import "./PageContentContainer.css";
import Aside from "./Aside";
import Footer from "./Footer";
import MainContent from "./MainContent";

function PageContentContainer() {
    return (
        <div id="page-container" >
            <MainContent />
            <Aside />
            <Footer />
        </div>
    )
}

export default PageContentContainer;