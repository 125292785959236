import { Link } from "react-router-dom";
import './Trasparenza.css'

function Trasparenza() {


    return (

        <div className="center">
            <div className="text space">
                Settemotivi - Legge Trasparenza Anno 2023
            </div>
            <div className="text space">
                Settemotivi - ODV non ha ricevuto nell'anno 2023 contributi pubblici superiori a € 10.000/00.
            </div>
            <div>
                <table>
                    <tr>
                        <th>Soggetto erogatore</th>
                        <th>Data ricevimento</th>
                        <th>Importo erogato</th>
                        <th>Riferimento</th>
                    </tr>
                    <tr>
                        <td>REGIONE FRIULI VENEZIA GIULIA</td>
                        <td>2/6/2023</td>
                        <td>€ 752,98</td>
                        <td>CONTRIBUTI ALLE ODV, APS E FONDAZIONI ONLUS PER LO SVOLGIMENTO DI ATTIVITÀ DI INTERESSE GENERALE
                            - ARTT. 72-73 CODICE DEL TERZO SETTORE – DGR 1373/2021
                        </td>
                    </tr>
                    <tr>
                        <td>COMUNE DI PORDENONE</td>
                        <td>3/16/2023</td>
                        <td>€ 1.000,00</td>
                        <td>CONTRIBUTO ORDINARIO PER L’ATTIVITA’ IN AMBITO SOCIALE, SOCIO-SANITARIO, ASSISTENZIALE E
                            DELL’IMPEGNO CIVILE. ANNO 2022
                        </td>
                    </tr>
                    <tr>
                        <td>COMUNE DI PORDENONE</td>
                        <td>3/28/2023</td>
                        <td>€ 300,00</td>
                        <td>CONTRIBUTO PER LA REALIZZAZIONE DI INIZIATIVE E PER L’ATTIVITÀ SOCIALE IN AMBITO CULTURALE,
                            TURISTICO E RICREATIVO – ANNO 2022
                        </td>
                    </tr>
                    <tr>
                        <td>REGIONE FRIULI VENEZIA GIULIA</td>
                        <td>5/24/2023</td>
                        <td>€ 200,00</td>
                        <td>CONTRIBUTO PER L’ASSICURAZIONE DEI VOLONTARI AI SENSI DELL’ARTICOLO 9, COMMA 1, LETTERA A) DELLA
                            LEGGE REGIONALE 23/2012 E SUCCESSIVE MODIFICHE ED INTEGRAZIONI
                        </td>
                    </tr>
                    <tr>
                        <td>REGIONE FRIULI VENEZIA GIULIA</td>
                        <td>6/1/2023</td>
                        <td>€ 3.000,00</td>
                        <td>ACCONTO CONTRIBUTO PER INTERVENTI PROGETTUALI DI PARTICOLARE RILEVANZA 2023, AI SENSI DELL’ART.
                            9, COMMA 1, LETTERA C) DELLA LEGGE REGIONALE N. 23/2012
                        </td>
                    </tr>
                    <tr>
                        <td>REGIONE FRIULI VENEZIA GIULIA</td>
                        <td>6/26/2023</td>
                        <td>€ 2.000,00</td>
                        <td>SALDO CONTRIBUTO PER INTERVENTI PROGETTUALI DI PARTICOLARE RILEVANZA 2022, AI SENSI DELL’ART. 9,
                            COMMA 1, LETTERA C) DELLA LEGGE REGIONALE N. 23/2012
                        </td>
                    </tr>
                    <tr>
                        <td>COMUNE DI PRATA DI PORDENONE</td>
                        <td>10/17/2023</td>
                        <td>€ 150,00</td>
                        <td>
                            Acconto contributo anno 2023
                        </td>
                    </tr>
                    <tr>
                        <td>COMUNE DI PORDENONE</td>
                        <td>12/7/2023</td>
                        <td>€ 500,00</td>
                        <td>ACCONTO CONTRIBUTO ORDINARIO PER L’ATTIVITA’ IN AMBITO SOCIALE, SOCIO-SANITARIO, ASSISTENZIALE E
                            DELL’IMPEGNO CIVILE. ANNO 2023
                        </td>
                    </tr>
                </table>
            </div>
            <div style={{"margin": "1rem"}}>
                pubblicazione dei contributi ricevuti ai sensi della legge 4 agosto 2017 n. 124 art. 1, commi da 125 a 127
            </div>
            <div style={{"margin": "1rem"}}>
                <Link to={"/"}>Torna alla home</Link>

            </div>
        </div>
    )
}

export default Trasparenza;